<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0 pt-5">
              <v-img
                contain
                max-width="100%"
                height="592"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/fairpay-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Forgot Password? 🔒
                  </p>
                  <p class="mb-2">
                    Enter your email and we'll send you instructions to reset your password
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="passwordRecoveryForm"
                    v-model="isValid"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      :rules="[validators.required, validators.emailValidator]"
                      outlined
                      label="Email"
                      placeholder="john@example.com"
                      hide-details
                      class="mb-4"
                    ></v-text-field>

                    <div class="mb-10">
                      <VueRecaptcha
                        :sitekey="siteKey"
                        :load-recaptcha-script="true"
                        @verify="handleReCaptchaSucess"
                        @error="handleReCaptchaError"
                      ></VueRecaptcha>
                    </div>

                    <v-btn
                      block
                      type="submit"
                      color="primary"
                      :disabled="!isValid || reCaptchaToken == null"
                      :loading="loading"
                    >
                      Send reset link
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar.isSnackbarVisible">
      <span v-html="snackbar.snackbarMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar.isSnackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import { mdiChevronLeft } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha
  },
  setup() {
    const siteKey = computed(() => {
      return process.env.VUE_APP_RECAPTCHA_SITEKEY
    });
    const reCaptchaToken = ref(null)
    const passwordRecoveryForm = ref(null)
    const loading = ref(false)
    const isPasswordVisible = ref(false)
    const email = ref('')
    const isValid = ref(false)

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: '3000',
    })

    const validate = () => {
      passwordRecoveryForm.value.validate()
    }


    const handleReCaptchaError = () => {
      reCaptchaToken.value = null
    };

    const handleReCaptchaSucess = (response) => {
      reCaptchaToken.value = response
    };

    const showSnackbarMessage = data => {
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = data.message
    }

    const handleFormSubmit = () => {
      if (isValid.value) {
        loading.value = true

        axios
          .post(`/userAccess/authentication/password-reset-link?email=${email.value}&token=${reCaptchaToken.value}`)
          .then(() => {
            showSnackbarMessage({ type: 'info', message: 'The instructions to reset you password have been sent to the registered e-mail' })
            email.value = ''
          })
          .catch(error => {
            showSnackbarMessage({ message: error.response, type: 'error' })
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        validate()
      }
    }

    return {
      handleFormSubmit,
      showSnackbarMessage,
      validate,
      handleReCaptchaError,
      handleReCaptchaSucess,
      siteKey,
      reCaptchaToken,
      loading,
      isPasswordVisible,
      email,
      snackbar,
      isValid,

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      passwordRecoveryForm,
      icons: {
        mdiChevronLeft,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
